/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control" v-if="typeValue === 0">
        <b-tooltip
          v-if="clients.length === 0 || warehouses.length === 0"
          label="Primero debes agregar clientes y almacenes al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled
            >Agregar cuenta por cobrar</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="addExpenseAccount()"
          v-if="clients.length > 0 && warehouses.length"
          >Agregar cuenta por cobrar</b-button
        >
      </div>
      <div class="control" v-if="typeValue === 1">
        <b-button
          type="is-primary"
          @click="openAddPaymentMultiplePPD()"
          v-if="clients.length > 0"
          >Agregar mismo pago a multiples cuentas PPD</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="removeThresholdMissingAccounts()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.treasury.removeMissingAccountsIncomes ===
              'S' &&
            permissions &&
            permissions.treasury.removeMissingAccountsIncomes === 'S'
          "
          >Eliminar saldos menores</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por clientes" label-position="inside">
          <b-select
            placeholder="Filtrar por clientes"
            icon="account-group-outline"
            v-model="filterClient"
            class="global-lists-filters-control"
          >
            <option value="">Todos los clientes</option>
            <option v-for="client in clients" :value="client" :key="client._id">
              {{ client ? client.NOMBRE_EMPRESA : "" }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de cuentas por cobrar de ventas:
      <b>{{ filteredAccounts.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="filteredAccounts"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedIncomes"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NUM_FOLIO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">
            No hay cuentas por cobrar de ventas
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
            :cell-class="getRowCellClass(props.row)"
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <div class="controls">
                <b-tooltip
                  v-if="props.row.RESTA <= 0 && props.row.STATUS !== 'CA'"
                  label="La cuenta por cobrar ha sido pagada en su totalidad"
                  multilined
                >
                  <b-button type="is-primary" disabled
                    >Agregar nuevo pago</b-button
                  >
                </b-tooltip>
                <b-button
                  type="is-primary"
                  @click="addPayment(props.row)"
                  v-if="props.row.RESTA > 0 && props.row.STATUS !== 'CA'"
                  >Agregar nuevo pago</b-button
                >
                <b-button
                  type="is-primary"
                  @click="useAdvancedPayment(props.row)"
                  v-if="
                    props.row.RESTA > 0 &&
                    props.row.STATUS !== 'CA' &&
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury.useAdvancedPayment ===
                      'S' &&
                    permissions &&
                    permissions.treasury.useAdvancedPayment === 'S' &&
                    checkCanUseAdvancedPayment(props.row)
                  "
                  >Agregar nuevo pago usando anticipo
                </b-button>
                <b-button
                  type="is-primary"
                  @click="showIncomeDetails(props.row)"
                  >Ver detalles y pagos</b-button
                >
                <b-button
                  v-if="type === 0"
                  type="is-primary"
                  @click="
                    startCancelEvent(
                      'danger',
                      function () {
                        cancelIncome(props.row);
                      },
                      'cancelar la cuenta por cobrar'
                    )
                  "
                  >Cancelar cuenta por cobrar</b-button
                >
                <b-button
                  type="is-primary"
                  @click="addManualMovement(props.row)"
                  v-if="
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury
                      .addManualMovementIncome === 'S' &&
                    permissions &&
                    permissions.treasury.addManualMovementIncome === 'S'
                  "
                  >Agregar movimientos manuales</b-button
                >
                <b-button
                  type="is-primary"
                  @click="manualMovementList(props.row)"
                  v-if="
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury
                      .addManualMovementIncome === 'S' &&
                    permissions &&
                    permissions.treasury.addManualMovementIncome === 'S'
                  "
                  >Ver movimientos manuales</b-button
                >
                <b-button
                  type="is-primary"
                  @click="editClientAccount(props.row)"
                  v-if="
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury
                      .editIncomeBasicInformation === 'S' &&
                    permissions &&
                    permissions.treasury.editIncomeBasicInformation === 'S'
                  "
                  >Editar cuenta por pagar</b-button
                >
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import AddNewPayment from "./AddNewPayment";
import IncomeDetailsTabsModal from "./IncomeDetailsTabsModal";
import AddNewIncome from "./AddNewIncome";
import UseAdvancedPayment from "./UseAdvancedPayment";
import AddManualMovementAccountModal from "./AddManualMovementAccountModal";
import ManualMovementsAccountsList from "./ManualMovementsAccountsList";
import EditClientAccountModal from "./EditClientAccountModal";
import RemoveMissingAccounts from "./RemoveMissingAccounts";
import AddPaymentMultiplePPD from "./AddPaymentMultiplePPD";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import moment from "moment";
import { EventBus } from "@/event-bus";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ClientsAccounts",
  props: ["type"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeValue: this.type,
      perPage: 50,
      checkedIncomes: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "NUM_FOLIO",
            label: "Folio de cuenta por cobrar",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CLIENTE_FOR",
            label: "Cliente",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "ALMACEN_FOR",
            label: "Almacen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_VENDEDOR_FOR",
            label: "Vendedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "DESCRIPCION",
            label: "Descripcion",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "FACTURAS_FOR",
            label: "Facturas",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "posFormatted",
            label: "Punto de venta",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "NO_DOCUM",
            label: "Número de documento",
            sortable: true,
            searchable: true,
            display: false,
          },

          {
            field: "STATUS_FORMATED",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "FORMA_P_FORMATED",
            label: "Forma de pago de venta",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "METODO_P_FORMATED",
            label: "Metodo de pago",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "DIAS_SIN_PAGO",
            label: "Días sin pago",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "FORMAS_P_FORMATED",
            label: "Formas de pago usadas",
            sortable: true,
            searchable: true,
            display: false,
          },

          {
            field: "IMPORTE_E",
            label: "Sub total",
            sortable: true,
            searchable: true,
            display: false,
            money: true,
          },
          {
            field: "DESC_GTO",
            label: "Descuentos",
            sortable: true,
            searchable: true,
            display: false,
            money: true,
          },
          {
            field: "COST_GTO",
            label: "Indirectos",
            sortable: true,
            searchable: true,
            display: false,
            money: true,
          },

          {
            field: "IVA_GTO",
            label: "Total IVA",
            sortable: true,
            searchable: true,
            display: false,
            money: true,
          },
          {
            field: "TOTAL_GTO",
            label: "Total a pagar",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
            customSort: this.sortByTotal,
          },
          {
            field: "RESTA",
            label: "Restante a pagar",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
            customSort: this.sortByMisingQuantity,
          },
          {
            field: "PERI",
            label: "Plazo en días",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "formatedCreationDate",
            label: "Fecha de creación",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "formatedExpirationDate",
            label: "Fecha de vencimiento",
            sortable: true,
            searchable: true,
            display: false,
            customSort: this.sortByDateExpiration,
          },
          {
            field: "DIAS_VEN",
            label: "Días vencida",
            sortable: true,
            searchable: true,
            display: false,
          },
        ],
        "clients-accounts-list-columns"
      ),
      masks: Masks,
      brandRequiredErrors: {
        CLAVE_MARC: false,
        DESC_MARC: false,
      },
      filterClient: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    EventBus.$on("closeIncomeModal", () => {
      this.reloadInformation();
    });
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      allPromises.push(
        this.$store.dispatch("GETINCOMES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    getRowCellClass(rowInformation) {
      let rowClass = "";

      if (
        rowInformation.DIAS_VEN > 0 &&
        rowInformation.STATUS !== "PA" &&
        rowInformation.STATUS !== "CA"
      ) {
        rowClass = "red-cell";
      }

      if (
        rowInformation.METODO_P_FORMATED ===
          "Pago en una sola exhibición (PUE)" &&
        rowInformation.DIAS_SIN_PAGO >= 5
      ) {
        rowClass = "orange-cell";
      }

      return rowClass;
    },
    addExpenseAccount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewIncome,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: false,
      });
    },
    addPayment(incomeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewPayment,
        props: {
          income: incomeInformation,
          type: this.type === 0 ? "Ingreso" : "Venta",
          accountType: "CC",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: false,
      });
    },
    useAdvancedPayment(incomeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: UseAdvancedPayment,
        props: {
          income: incomeInformation,
          type: this.type === 0 ? "Compra" : "Gasto",
          accountType: "CC",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: false,
      });
    },
    showIncomeDetails(incomeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: IncomeDetailsTabsModal,
        props: {
          income: incomeInformation,
          type: this.type === 0 ? "Ingreso" : "Venta",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: false,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateExpiration(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_VEN).getTime() || -Infinity) -
          (new Date(a.FECHA_VEN).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_VEN).getTime() || -Infinity) -
          (new Date(b.FECHA_VEN).getTime() || -Infinity)
        );
      }
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.RESTA) - Number(b.RESTA);
      } else {
        return Number(b.RESTA) - Number(a.RESTA);
      }
    },
    sortByTotal(a, b, isAsc) {
      if (isAsc) {
        return Number(a.TOTAL_GTO) - Number(b.TOTAL_GTO);
      } else {
        return Number(b.TOTAL_GTO) - Number(a.TOTAL_GTO);
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelIncome(income) {
      try {
        let response = await this.$store.dispatch("CANCELINCOME", {
          incomeId: income._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la cuenta por cobrar",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar la cuenta por cobrar en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar cuenta por cobrar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETINCOMES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
    checkCanUseAdvancedPayment(incomeInformation) {
      if (!incomeInformation.CLAVE_VENTA) {
        return true;
      }

      return incomeInformation.CLAVE_VENTA.STATUS_FAC !== "TI";
    },
    addManualMovement(incomeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddManualMovementAccountModal,
        props: {
          type: 1,
          incomeId: incomeInformation._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    manualMovementList(incomeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ManualMovementsAccountsList,
        props: {
          movementsList: incomeInformation.MOVIMIENTOS_MANUALES
            ? incomeInformation.MOVIMIENTOS_MANUALES
            : [],
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    editClientAccount(incomeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EditClientAccountModal,
        props: {
          income: incomeInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    removeThresholdMissingAccounts() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: RemoveMissingAccounts,
        props: {
          type: 1,
          accountType: this.type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddPaymentMultiplePPD() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPaymentMultiplePPD,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    formatClientAccount(singleIncome) {
      let singleIncomeWithProps = { ...singleIncome };

      if (this.type === 1) {
        if (
          singleIncomeWithProps.CLAVE_VENTA &&
          singleIncomeWithProps.CLAVE_VENTA.NOMBRE_ALMACEN_ORIGEN
        ) {
          singleIncomeWithProps.ALMACEN_FOR =
            singleIncomeWithProps.CLAVE_VENTA.NOMBRE_ALMACEN_ORIGEN;
        }
      }

      if (this.type === 2) {
        if (
          singleIncomeWithProps.CLAVE_VENTA_SERVICIO &&
          singleIncomeWithProps.CLAVE_VENTA_SERVICIO.NOMBRE_ALMACEN_ORIGEN
        ) {
          singleIncomeWithProps.ALMACEN_FOR =
            singleIncomeWithProps.CLAVE_VENTA_SERVICIO.NOMBRE_ALMACEN_ORIGEN;
        }
      }

      singleIncomeWithProps.IVA_GTO = String(singleIncomeWithProps.IVA_GTO);
      singleIncomeWithProps.TOTAL_GTO = String(singleIncomeWithProps.TOTAL_GTO);
      singleIncomeWithProps.RESTA = String(singleIncomeWithProps.RESTA);

      singleIncomeWithProps.FORMA_P_FORMATED = "";
      if (singleIncome.CLAVE_VENTA && singleIncome.CLAVE_VENTA.FORMA_P) {
        singleIncomeWithProps.FORMA_P_FORMATED =
          singleIncome.CLAVE_VENTA.FORMA_P.DESCRIBE_P;
      }

      singleIncomeWithProps.METODO_P_FORMATED = "";
      if (
        singleIncome.CLAVE_VENTA &&
        singleIncome.CLAVE_VENTA.METODO_P === "D"
      ) {
        singleIncomeWithProps.METODO_P_FORMATED =
          "Pago en parcialidades o diferido (PPD)";
      }
      if (
        singleIncome.CLAVE_VENTA &&
        singleIncome.CLAVE_VENTA.METODO_P === "P"
      ) {
        singleIncomeWithProps.METODO_P_FORMATED =
          "Pago en una sola exhibición (PUE)";
      }

      if (
        singleIncome.CLAVE_VENTA_SERVICIO &&
        singleIncome.CLAVE_VENTA_SERVICIO.FORMA_P
      ) {
        singleIncomeWithProps.FORMA_P_FORMATED =
          singleIncome.CLAVE_VENTA_SERVICIO.FORMA_P.DESCRIBE_P;
      }

      singleIncomeWithProps.FORMAS_P_FORMATED = "";
      if (singleIncome.CLAVE_VENTA && singleIncome.CLAVE_VENTA.FORMA_P) {
        singleIncomeWithProps.FORMAS_P_FORMATED += `${singleIncome.CLAVE_VENTA.FORMA_P.DESCRIBE_P} `;
      }

      if (singleIncomeWithProps.PAGOS) {
        for (const singlePayment of singleIncomeWithProps.PAGOS) {
          if (
            !singleIncomeWithProps.FORMAS_P_FORMATED.includes(
              singlePayment.FORMA_P.DESCRIBE_P
            )
          ) {
            singleIncomeWithProps.FORMAS_P_FORMATED += `${singlePayment.FORMA_P.DESCRIBE_P} `;
          }
        }
      }

      if (singleIncome.CLAVE_VENTA) {
        singleIncomeWithProps.posFormatted =
          singleIncome.CLAVE_VENTA.POS === "S" ? "Sí" : "No";
      }

      singleIncomeWithProps.NOMBRE_VENDEDOR_FOR = "";
      if (
        singleIncome.CLAVE_VENTA &&
        singleIncome.CLAVE_VENTA.NOMBRE_VENDEDOR
      ) {
        singleIncomeWithProps.NOMBRE_VENDEDOR_FOR =
          singleIncome.CLAVE_VENTA.NOMBRE_VENDEDOR;
      }

      if (
        singleIncome.CLAVE_VENTA_SERVICIO &&
        singleIncome.CLAVE_VENTA_SERVICIO.NOMBRE_VENDEDOR
      ) {
        singleIncomeWithProps.NOMBRE_VENDEDOR_FOR =
          singleIncome.CLAVE_VENTA_SERVICIO.NOMBRE_VENDEDOR;
      }

      singleIncomeWithProps.FACTURAS_FOR = "";
      if (
        singleIncomeWithProps.CLAVE_VENTA &&
        singleIncomeWithProps.CLAVE_VENTA.FACTURAS &&
        singleIncomeWithProps.CLAVE_VENTA.FACTURAS.length > 0
      ) {
        for (const singleInvoice of singleIncomeWithProps.CLAVE_VENTA
          .FACTURAS) {
          singleIncomeWithProps.FACTURAS_FOR += `${singleInvoice.CLAVE_FACTURA} `;
        }
      }

      if (singleIncomeWithProps.STATUS === "AC")
        singleIncomeWithProps.STATUS_FORMATED = "Pendiente de pagar";
      if (singleIncomeWithProps.STATUS === "PE")
        singleIncomeWithProps.STATUS_FORMATED = "Pendiente de pagar";
      if (singleIncomeWithProps.STATUS === "PA")
        singleIncomeWithProps.STATUS_FORMATED = "Totalmente pagada";
      if (singleIncomeWithProps.STATUS === "CA") {
        singleIncomeWithProps.STATUS_FORMATED = "Cancelada";
        singleIncomeWithProps.RESTA = 0;
      }
      if (singleIncomeWithProps.STATUS === "VE") {
        singleIncomeWithProps.STATUS_FORMATED = "Vencida";
      }

      singleIncomeWithProps.DIAS_SIN_PAGO = 0;
      if (
        singleIncomeWithProps.STATUS !== "PA" &&
        singleIncomeWithProps.STATUS !== "CA"
      ) {
        const currentDate = moment();
        const saleDate = moment(singleIncomeWithProps.FECHA);
        singleIncomeWithProps.DIAS_SIN_PAGO =
          currentDate.diff(saleDate, "days") + 1;
      }

      singleIncomeWithProps.ALMACEN_FOR = "";
      if (singleIncomeWithProps.CLAVE_ALMACEN) {
        singleIncomeWithProps.ALMACEN_FOR =
          singleIncomeWithProps.CLAVE_ALMACEN.NOMBRE_ALM;
      }

      singleIncomeWithProps.formatedCreationDate = moment(
        singleIncomeWithProps.FECHA
      ).format("DD-MM-YYYY HH:mm");
      singleIncomeWithProps.formatedExpirationDate = moment(
        singleIncomeWithProps.FECHA_VEN
      ).format("DD-MM-YYYY HH:mm");

      return singleIncomeWithProps;
    },
    formatInvoiceAccount(singleIncome, singleInvoice) {
      const singleInvoiceWithProps = { ...singleIncome };

      singleInvoiceWithProps.ALMACEN_FOR =
        singleIncome.CLAVE_VENTA.NOMBRE_ALMACEN_ORIGEN;

      singleInvoiceWithProps.IVA_GTO = String(singleInvoice.IVA);
      singleInvoiceWithProps.TOTAL_GTO = String(singleInvoice.TOTAL);
      singleInvoiceWithProps.RESTA = singleIncome.RESTA
        ? String(singleIncome.RESTA)
        : String(singleIncome.RESTA);

      singleInvoiceWithProps.FORMA_P_FORMATED = "";
      if (singleIncome.CLAVE_VENTA && singleIncome.CLAVE_VENTA.FORMA_P) {
        singleInvoiceWithProps.FORMA_P_FORMATED =
          singleIncome.CLAVE_VENTA.FORMA_P.DESCRIBE_P;
      }

      singleInvoiceWithProps.METODO_P_FORMATED = "";
      if (
        singleIncome.CLAVE_VENTA &&
        singleIncome.CLAVE_VENTA.METODO_P === "D"
      ) {
        singleInvoiceWithProps.METODO_P_FORMATED =
          "Pago en parcialidades o diferido (PPD)";
      }

      if (
        singleIncome.CLAVE_VENTA &&
        singleIncome.CLAVE_VENTA.METODO_P === "P"
      ) {
        singleInvoiceWithProps.METODO_P_FORMATED =
          "Pago en una sola exhibición (PUE)";
      }

      if (
        singleIncome.CLAVE_VENTA_SERVICIO &&
        singleIncome.CLAVE_VENTA_SERVICIO.FORMA_P
      ) {
        singleInvoiceWithProps.FORMA_P_FORMATED =
          singleIncome.CLAVE_VENTA_SERVICIO.FORMA_P.DESCRIBE_P;
        singleInvoiceWithProps.FORMAS_P_FORMATED =
          singleIncome.CLAVE_VENTA_SERVICIO.FORMA_P.DESCRIBE_P;
      }

      if (singleIncome.CLAVE_VENTA) {
        singleInvoiceWithProps.posFormatted =
          singleIncome.CLAVE_VENTA.POS === "S" ? "Sí" : "No";
      }

      singleInvoiceWithProps.NOMBRE_VENDEDOR_FOR = "";
      if (
        singleIncome.CLAVE_VENTA &&
        singleIncome.CLAVE_VENTA.NOMBRE_VENDEDOR
      ) {
        singleInvoiceWithProps.NOMBRE_VENDEDOR_FOR =
          singleIncome.CLAVE_VENTA.NOMBRE_VENDEDOR;
      }

      singleInvoiceWithProps.FACTURAS_FOR = "";
      if (singleInvoice.CLAVE_FACTURA) {
        singleInvoiceWithProps.FACTURAS_FOR = singleInvoice.CLAVE_FACTURA;
      }

      if (singleIncome.STATUS === "AC")
        singleInvoiceWithProps.STATUS_FORMATED = "Pendiente de pagar";
      if (singleIncome.STATUS === "PE")
        singleInvoiceWithProps.STATUS_FORMATED = "Pendiente de pagar";
      if (singleIncome.STATUS === "PA")
        singleInvoiceWithProps.STATUS_FORMATED = "Totalmente pagada";
      if (singleIncome.STATUS === "CA") {
        singleInvoiceWithProps.STATUS_FORMATED = "Cancelada";
        singleInvoiceWithProps.RESTA = 0;
      }
      if (singleIncome.STATUS === "VE") {
        singleInvoiceWithProps.STATUS_FORMATED = "Vencida";
      }

      singleInvoiceWithProps.DIAS_SIN_PAGO = 0;
      if (singleIncome.STATUS !== "PA" && singleIncome.STATUS !== "CA") {
        const currentDate = moment();
        const saleDate = moment(singleInvoice.FECHA);
        singleInvoiceWithProps.DIAS_SIN_PAGO =
          currentDate.diff(saleDate, "days") + 1;
      }

      singleInvoiceWithProps.formatedCreationDate = moment(
        singleInvoice.FECHA
      ).format("DD-MM-YYYY HH:mm");
      singleInvoiceWithProps.formatedExpirationDate = moment(
        singleIncome.FECHA_VEN
      ).format("DD-MM-YYYY HH:mm");

      singleInvoiceWithProps.FACTURA_ID = singleInvoice._id;

      return singleInvoiceWithProps;
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "clients-accounts-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };

        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    clientsAccounts() {
      let allClientsAccounts = [];

      for (const singleIncome of this.$store.getters.INCOMES) {
        if (
          this.type === 1 &&
          singleIncome.CLAVE_VENTA &&
          singleIncome.CLAVE_VENTA.METODO_P === "D" &&
          singleIncome.CLAVE_VENTA.FACTURAS &&
          singleIncome.CLAVE_VENTA.FACTURAS.length > 0
        ) {
          for (const singleInvoice of singleIncome.CLAVE_VENTA.FACTURAS) {
            const formattedInvoiceInformation = this.formatInvoiceAccount(
              singleIncome,
              singleInvoice
            );

            allClientsAccounts.push(formattedInvoiceInformation);
          }
        } else {
          const formattedIncomeInformation =
            this.formatClientAccount(singleIncome);

          allClientsAccounts.push(formattedIncomeInformation);
        }
      }

      return allClientsAccounts.filter((singleIncome) => {
        if (this.type === 0) {
          return singleIncome.TIPO_ING === "I";
        }

        if (this.type === 1) {
          return singleIncome.TIPO_ING === "V";
        }

        if (this.type === 2) {
          return singleIncome.TIPO_ING === "S";
        }

        return false;
      });
    },
    filteredAccounts() {
      if (this.filterClient === "") {
        return this.clientsAccounts.map((singleAccount) => {
          let singleAccountWithProps = { ...singleAccount };

          singleAccountWithProps.CLIENTE_FOR =
            singleAccount.CLAVE_CLIENTE.NOMBRE_EMPRESA;

          return singleAccountWithProps;
        });
      }
      return this.clientsAccounts.filter((singleAccount) => {
        if (singleAccount.CLAVE_CLIENTE._id === this.filterClient._id) {
          return true;
        }
        return false;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
